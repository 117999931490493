<template>
    <div class="user">
        <ion-nav-link router-direction="forward" @click="">
            <div class="user__body">
                <figure class="user__bg-image">
                    <Swiper
                        :modules="[SwiperPagination, SwiperNavigation]"
                        :pagination="{
                            clickable: true,
                        }"
                        :navigation="{
                            clickable: true,
                        }"
                    >
                        <SwiperSlide v-if="!user.thumbnail" :key="0">
                            <img
                                src="~/assets/images/placeholders/user.png"
                                @click="emit('profileClick')"
                            />
                        </SwiperSlide>

                        <SwiperSlide
                            v-for="image in buildUserPhotos(user)"
                            :key="image.url"
                        >
                            <ImageResource
                                :src="image.url"
                                @click="emit('profileClick')"
                            />
                        </SwiperSlide>
                    </Swiper>
                </figure>

                <div class="user__stats">
                    <UserDistanceText v-if="withDistance" :user="user" />

                    <template v-else>
                        <div class="user__stats__item">
                            <!-- prettier-ignore -->
                            <strong>{{ _.isNil(user.attendance_count) ? '-' : user.attendance_count }}</strong>
                            <span>{{ __('Účasť') }}</span>
                        </div>
                        <div class="user__stats__item">
                            <!-- prettier-ignore -->
                            <strong>{{ _.isNil(user.events_count) ? '-' : user.events_count }}</strong>
                            <span>{{ __('Partičky') }}</span>
                        </div>
                    </template>
                </div>

                <div class="user__content">
                    <div class="user__header">
                        <div class="user__header-content">
                            <slot name="header_top" />

                            <h3 class="user__name">
                                {{ user.username }}

                                <small class="user__age" v-if="user.age">
                                    {{ user.age }}
                                </small>
                            </h3>

                            <div class="user__badges_wrapper">
                                <ProfileBadges
                                    :user="user"
                                    :orange="true"
                                    :visibleBadgeIds="visibleBadgeIds"
                                />
                            </div>

                            <div class="user__description">
                                <div
                                    class="user__duration"
                                    v-if="user.accountTypeName"
                                >
                                    <ion-icon :icon="personCircleOutline" />
                                    <div class="wrap-text">
                                        {{ user.accountTypeName }}
                                    </div>
                                </div>
                                <div class="user__duration">
                                    <ion-icon :icon="calendarOutline" />
                                    <!-- prettier-ignore -->
                                    <div class="wrap-text">{{ __('Členom %s', moment(user.created_at).fromNow(true)) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-nav-link>
    </div>
</template>

<script setup>
import moment from 'moment';
import _ from 'lodash';
import { calendarOutline, personCircleOutline } from 'ionicons/icons';

const props = defineProps({
    user: {},
    isPublic: {},
    withDistance: {
        default: false,
    },
    visibleBadgeIds: {},
});

const emit = defineEmits(['profileClick']);
</script>

<style lang="scss" scoped>
.user__body {
    position: relative;
    border-radius: 1.6rem;
    overflow: hidden;
    height: 40rem;
    display: flex;
    align-items: flex-end;

    @include more-than(md) {
        border-radius: 0;
    }
}

.user__bg-image {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    .swiper {
        --swiper-navigation-sides-offset: 1.5rem;
        --swiper-bottom-color: rgba(0, 0, 0, 0.6);

        height: 100%;
        width: 100%;

        :deep(.swiper-slide:before) {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            pointer-events: none;
            background-image: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.075) 0%,
                rgba(0, 0, 0, 0.15) 60%,
                var(--swiper-bottom-color) 100%
            );
        }

        :deep(.swiper-pagination) {
            --swiper-pagination-top: 2rem;
            --swiper-pagination-bottom: auto;

            padding: 0 1.5rem;
            text-align: left;

            .swiper-pagination-bullet {
                background: var(--ion-text-color);
                transition: all 0.2s;
                opacity: 0.6;
                height: 0.75rem;
                width: 1rem;
                border-radius: 0.45rem;

                &.swiper-pagination-bullet-active {
                    background: #fff;
                    width: 2rem;
                    border-radius: 2rem;
                }
            }
        }

        :deep(.swiper-button-next),
        :deep(.swiper-button-prev) {
            background: url('~/assets/images/icons/arrow_circle_right.svg')
                no-repeat center center;

            &::after {
                display: none;
            }
        }

        :deep(.swiper-button-prev) {
            transform: rotate(180deg);
        }
    }

    :deep(img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.user__info {
    display: flex;
    flex-direction: column;

    strong {
        font-weight: 500;
        font-size: 1rem;
        color: #ffffff;
    }

    span {
        font-weight: 400;
        font-size: 1rem;
        color: #89cbd2;
    }
}

.user__content {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 2rem;
    pointer-events: none;
}

.user__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
    margin-top: auto;

    .user__header-content {
        width: 100%;
    }
}

.user__stats {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: flex;
    gap: 2.4rem;
    z-index: 2;
    pointer-events: none;
}

.user__stats__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: right;
    font-family: var(--secondary-font-family);
    position: relative;

    &:first-child {
        border-right: 1px solid white;
        padding-right: 1.2rem;
        margin-right: -1.2rem;
    }

    strong {
        font-weight: 700;
        font-size: 2.4rem;
        color: #ffffff;
        line-height: 1;
    }

    span {
        font-weight: 600;
        font-size: 1.2rem;
        color: #ffffff;
        text-transform: capitalize;
    }
}

.user__badges_wrapper {
    display: flex;
    margin-bottom: 1rem;
}

.user__name {
    font-weight: 700;
    font-family: var(--secondary-font-family);
    color: #ffffff;
    line-height: 125%;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 3.2rem;
    letter-spacing: -0.31px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0.75rem 0 0rem;

    @include more-than(md) {
        margin: 1rem 0;
    }

    .user__age {
        font-weight: 400;
    }
}

.user__description {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.user__duration {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    color: #ffffff;
    gap: 0.5rem;

    .wrap-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: calc(100% - 1rem);
    }

    > * {
        flex-shrink: 0;
    }
}
</style>
